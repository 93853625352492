import React, { useState } from "react";

const UseFileInput = ({
  setFieldValue,
  errors = "",
  touched = "",
  id = "",
  title = true,
  disable,
}) => {
  const [fileName, setfileName] = useState();
  const [fileSizePx, setfileSizePx] = useState();

  const onChangePhoto = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue(`${id}-photo`, file);
    sizeOnPx(file);
    setfileName(file.name);
  };

  const sizeOnPx = async (file) => {
    const reader = new FileReader();
    //Read the contents of Image File.
    reader.readAsDataURL(file);

    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      const image = new Image();
      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;
      //Validate the File Height and Width.
      return (image.onload = function () {
        const height = this.height;
        const width = this.width;
        if (height >= 70 && width >= 70) {
          setfileSizePx(true);
          return;
        }
        setfileSizePx(false);
        return;
      });
    };
  };
  return (
    <label
      className="input-img"
      style={
        errors.photo && touched.photo
          ? { border: "2px solid red", borderRadius: "6px 0px 0px 6px" }
          : {}
      }
      htmlFor={`${id}_photo`}
    >
      <div className="input-img__left">
        <span>Upload</span>
      </div>
      <span className="input-img__right">
        {title && fileName ? fileName : "Загрузите фото"}
      </span>
      <input
        className="form-input__none"
        type="file"
        id={`${id}_photo`}
        name={`${id}_photo`}
        disabled={disable}
        onChange={(event) => onChangePhoto(event, setFieldValue)}
      />
    </label>
  );
};

export default UseFileInput;

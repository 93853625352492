import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import UseFileInput from "../../../../../UseFileInput";

import { useDispatch } from "react-redux";
import { ReactComponent as BugerIcon } from "../../../../../../style/img/burger_icon.svg";
const FormCategory = ({
  closeModal,
  category_id,
  submit,
  title,
  button_text,
  id,
  cat_data,
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={`modalDropdown-category-wrapper ${open ? "open" : "close"} `}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onClick={() => setOpen(!open)}
      >
        <span className="catalog_sideitem_titel">{title}</span>
        <BugerIcon />
      </div>

      <Formik
        initialValues={{
          [`${id}-name_ru`]: cat_data?.name_ru ? cat_data.name_ru : "",
          [`${id}-name_ua`]: cat_data?.name_ua ? cat_data.name_ua : "",
          [`${id}-photo`]: [],
        }}
        validationSchema={Yup.object({
          [`${id}-name_ru`]: Yup.string().min(2, "min 2").required("required"),
          [`${id}-name_ua`]: Yup.string().min(2, "min 2").required("required"),
          // photo: Yup.mixed()
          //   .nullable()
          //   .required("A file is required")
          //   .test(
          //     "size mb",
          //     "The photo size must not be greater than 5 Mb",
          //     (value) => !value || (value && value.size <= 5 * 1024 * 1024)
          //   ),
        })}
        onSubmit={async (body) => {
          // collect data

          const formData = new FormData();

          Object.entries(body).map(([key, value]) => {
            if (key === `${id}-photo`) {
              return formData.append("photo", value);
            }
            return formData.append(key.split("-")[1], value);
          });
          if (!cat_data) formData.append("parentSub_id", category_id);
          const req = await dispatch(submit(formData));

          if (req.payload === 200) closeModal();
        }}
      >
        {({ setFieldValue, errors, touched }) => (
          <div
            style={{
              maxHeight: open ? "500px" : "0px",
              overflow: "hidden",
              transition: "max-height 0.5s ease-in-out",
            }}
          >
            {open && (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <ErrorMessage
                      name={`${id}-name_ua`}
                      className="error"
                      component="div"
                    />
                    <span>Назва категорії ua</span>
                    <Field
                      className="form__input"
                      type="text"
                      placeholder="Назва"
                      name={`${id}-name_ua`}
                    />
                  </div>
                  <div>
                    <ErrorMessage
                      name={`${id}-name_ru`}
                      className="error"
                      component="div"
                    />
                    <span>Назва категорії ru</span>
                    <Field
                      className="form__input"
                      type="text"
                      placeholder="Назва"
                      name={`${id}-name_ru`}
                    />
                  </div>
                </div>

                <ErrorMessage
                  name={`${id}-photo`}
                  className="error"
                  component="div"
                />
                <span>Завантаження фото для категорії</span>
                <UseFileInput
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  id={id}
                />
                <button type="submit">{button_text}</button>
              </Form>
            )}
          </div>
        )}
      </Formik>
    </>
  );
};

export default FormCategory;

import { useState } from "react";
import UseModal from "../../../../../UseModal";
import { ReactComponent as SettingSvg } from "../../../../../../style/img/setting.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCatagory,
  getCategoryTable,
  updateCategoryTable,
} from "../../../../../../store/slices/tableSlice";

import { createCategoryTable } from "../../../../../../store/slices/tableSlice";
import FormCategory from "./FormCategory";
import ModalDelete from "../../../ModalDelete";

import "../form.css";

const TableNavSetting = ({ category_id, cat_data }) => {
  const categoryData = useSelector((state) => state.table.category);
  const dispatch = useDispatch();
  const [modalCreate, setModalCreate] = useState({
    visible: false,
    item: null,
  });

  const closeModal = () =>
    setModalCreate(() => {
      return {
        item: null,
        visible: false,
      };
    });

  const onModal = () => {
    setModalCreate(() => {
      return {
        item: null,
        visible: true,
      };
    });
  };

  const findTreeRootAndIdsByTargetId = (categories, targetId) => {
    let categoryIds = [];
    let foundTarget = false;

    const findCategory = (category) => {
      if (category.id === targetId) {
        foundTarget = true;
      }

      if (foundTarget) {
        categoryIds.push(category.id);
      }

      if (category.subMenu) {
        for (const subCategory of category.subMenu) {
          findCategory(subCategory);
        }
      }

      return foundTarget;
    };

    for (const category of categories) {
      if (findCategory(category)) {
        break;
      }
    }

    return categoryIds;
  };

  const submitDelete = async (id) => {
    const req = await dispatch(
      deleteCatagory(findTreeRootAndIdsByTargetId(categoryData, id))
    );
    if (req.payload === 200) {
      dispatch(getCategoryTable());
    }
    return req;
  };
  const submitUpdate = (data) => {
    return updateCategoryTable({ data, id: category_id });
  };
  return (
    <>
      <div className="tableNav_setting_button" onClick={() => onModal()}>
        <SettingSvg />
      </div>

      <UseModal
        visible={modalCreate.visible}
        title="Налаштування категорії"
        content={
          <div className="modalCreate_wrapper">
            <FormCategory
              category_id={category_id}
              closeModal={closeModal}
              submit={submitUpdate}
              id={"cat"}
              title={"Редагувати категорію"}
              button_text={"Зберегти"}
              cat_data={cat_data}
            />
            <FormCategory
              category_id={category_id}
              closeModal={closeModal}
              submit={createCategoryTable}
              title={"Додати підкатегорію"}
              button_text={"Додати підкатегорію"}
              id={"sub"}
            />

            <div
              className="modal_tableNav_buttonWrapper"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <ModalDelete
                submit={submitDelete}
                checkedRowLength={1}
                checkedIds={category_id}
              />

              <button onClick={closeModal}>Скасувати</button>
            </div>
          </div>
        }
        onClose={closeModal}
      />
    </>
  );
};

export default TableNavSetting;

import React from "react";
import UseFileInput from "../../../../useHook/UseFileInput";
import UseSlider from "../../../../useHook/slider/UseSlider";
import none_img from "../../../../style/img/no_image.png";
import close_red from "../../../../style/img/close_red.png";
const ProductPhotoMain = ({ onChange, img, cancel, disable = false }) => {
  return (
    <div className="ProductPhotoMain">
      <span className="ProductPhotoMain_img_title">Основное фото</span>
      <div className="ProductPhotoMain_img-wrapper">
        <img
          src={
            img
              ? typeof img === "string"
                ? img
                : URL.createObjectURL(img)
              : none_img
          }
          alt=""
        />
        {img && (
          <img
            style={{
              position: "absolute",
              top: "13px",
              right: "9px",
              width: "30px",
              height: "20px",
            }}
            src={close_red}
            alt="close"
            onClick={() => cancel()}
          />
        )}
      </div>
      <UseFileInput
        id={"main"}
        title={false}
        setFieldValue={onChange}
        disable={disable}
      />
    </div>
  );
};
const ProductPhotoSub = ({ onChange, images, cancel, disable = false }) => {
  const createSliderImage = (file, id) => (
    <div style={{ position: "relative" }}>
      <img
        src={
          file
            ? typeof file === "string"
              ? file
              : URL.createObjectURL(file)
            : none_img
        }
        onDragStart={(e) => e.preventDefault()}
        role="presentation"
        className="carousel-image"
      />
      <img
        style={{
          position: "absolute",
          top: "13px",
          right: "9px",
          width: "30px",
          height: "20px",
        }}
        src={close_red}
        alt="close"
        onClick={() => cancel(id)}
      />
    </div>
  );

  return (
    <div className="ProductPhotoSub">
      <span className="ProductPhotoSub_title">Дополнительное фото</span>
      <div className="ProductPhotoSub_slider">
        <UseSlider
          images={images.map(({ file, id }) => createSliderImage(file, id))}
        />
      </div>

      <div className="ProductPhotoSub_input">
        <UseFileInput
          id={"sub"}
          title={false}
          setFieldValue={onChange}
          disable={disable}
        />
      </div>
    </div>
  );
};

const ProductPhoto = ({
  onChangePhotoMain,
  mainPhoto,
  subPhoto,
  OnChangePhotoSub,
  cancelSubImage,
  cancelMainImage,
  disable,
}) => {
  return (
    <div className="ProductPhoto">
      <ProductPhotoMain
        onChange={onChangePhotoMain}
        img={mainPhoto}
        cancel={cancelMainImage}
        disable={disable}
      />
      <ProductPhotoSub
        onChange={OnChangePhotoSub}
        images={subPhoto}
        cancel={cancelSubImage}
        disable={disable}
      />
    </div>
  );
};

export default ProductPhoto;

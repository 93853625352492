export const columnsTableCounterparty = [
  {
    Header: "checkbox",
    accessor: "checkbox",
  },
  {
    Header: "ПІБ",
    accessor: "name",
  },
  {
    Header: "Телефон",
    accessor: "phone",
  },
  {
    Header: "Логiн",
    accessor: "login",
  },
];

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../axios";
import { changeDateFormat } from "../../utils/changeDateFormat";

// type can be discount or currency

export const createSettingByTypePost = createAsyncThunk(
  "settingSlice/createSettingByTypePost",
  async ({ data, type }, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.post(`/${type}`, data);

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSettingByType = createAsyncThunk(
  "settingSlice/getSettingByType",
  async ({ type, offset = 0, limit = 25 }, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api(`/${type}/?offset=${offset}&limit=${limit}`);

      dispatch(setData(req.data.data.data));
      dispatch(setTotal(req.data.data.total));

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  data: [],
  total: null,
  checkedDiscount: null,
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setData(state, { payload }) {
      state.data = payload;
    },
    setCheckedDiscount(state, { payload }) {
      state.checkedDiscount = payload;
    },
    setTotal(state, { payload }) {
      state.total = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setData, setTotal, setCheckedDiscount } = settingSlice.actions;

export default settingSlice.reducer;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkboxForDetailsOrDelete } from "../../../../store/slices/tableSlice";
import UseModal from "../../../../useHook/UseModal";
import close_red from "../../../../style/img/close_red.png";
const AddCounterpartyButton = ({ title, checkedCounterparty, navigateTo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalCreate, setModalCreate] = useState({
    visible: false,
    item: null,
  });

  const closeModal = () =>
    setModalCreate(() => {
      return {
        item: null,
        visible: false,
      };
    });

  const onModal = () => {
    setModalCreate(() => {
      return {
        item: null,
        visible: true,
      };
    });
  };
  return (
    <div>
      <span>{title}</span>
      <div className="purchaseCounterpary_wrapper" style={{ display: "flex" }}>
        <span
          onClick={() => {
            if (!checkedCounterparty) {
              navigate(navigateTo);
            }
          }}
          className="purchaseCounterpary"
        >
          {checkedCounterparty && checkedCounterparty.name}
        </span>
        {checkedCounterparty && (
          <div
            className="purchaseCounterparty_imgWrapper"
            onClick={() => dispatch(checkboxForDetailsOrDelete([]))}
          >
            <img src={close_red} alt="close" />
          </div>
        )}
        {checkedCounterparty ? (
          <button
            type="button"
            className="catalogueCreate_button"
            onClick={() => onModal()}
          >
            Iнфо
          </button>
        ) : (
          <button
            type="button"
            className="catalogueCreate_button"
            onClick={() => navigate(navigateTo)}
          >
            Додати
          </button>
        )}
      </div>
      <UseModal
        visible={modalCreate.visible}
        title="Контрагент"
        content={
          <div className="modalDelete_wrapper">
            <span style={{ margin: "0" }} className="modalDelete_promo">
              Баланс: {checkedCounterparty && checkedCounterparty.balance}$
            </span>
            <div className="modalDelete_buttonWrapper">
              <button
                className="modalDelete_cancel"
                style={{
                  color: "#fff",
                  backgroundColor: "#008947",
                  width: "100%",
                }}
                onClick={closeModal}
              >
                Добре
              </button>
            </div>
          </div>
        }
        onClose={closeModal}
      />
    </div>
  );
};

export default AddCounterpartyButton;

import { useState, useLayoutEffect, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDate } from "../../store/slices/tableSlice";
import Calendar from "react-calendar";
import { ReactComponent as Calendar_icon } from "../../style/img/calendar.svg";
import "react-calendar/dist/Calendar.css";
import "./dropdownCalendar.css";

const DropDownCalendar = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!isOpen);
  const dateState = useSelector((state) => state.table.date);
  const [isOpenCalendar, setOpenCalendar] = useState({
    visible: false,
    accessor: null,
    data: null,
    minDate: null,
  });
  const [dateNewState, setDateNewState] = useState({
    dateMin: "",
    dateMax: "",
  });
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };
  const changeDateNew = ({ name, data }) => {
    setDateNewState((dataInput) => ({
      ...dataInput,
      [name]: formatDate(data),
    }));
  };
  const onChangeCalendar = (date) => {
    changeDateNew({ name: isOpenCalendar.accessor, data: date });
    setOpenCalendar({
      visible: false,
      accessor: null,
      data: null,
    });
  };
  function parseDateString(dateString) {
    if (!dateString) return null;
    const parts = dateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    return new Date(year, month, day);
  }
  const handleButton = () => {
    dispatch(setDate(dateNewState));
    toggleDropdown();
    setOpenCalendar({
      visible: false,
      accessor: null,
      data: null,
      minDate: null,
    });
  };
  useLayoutEffect(() => {
    const today = new Date();
    const agoOneDays = new Date();
    const nextOneDays = new Date();
    nextOneDays.setDate(today.getDate() + 1);
    agoOneDays.setDate(today.getDate() - 1);

    const agoOneDayFormatted = formatDate(agoOneDays);
    const nextOneDaysFormatted = formatDate(nextOneDays);

    dispatch(
      setDate({
        dateMin: agoOneDayFormatted,
        dateMax: nextOneDaysFormatted,
      })
    );
    setDateNewState(() => ({
      dateMin: agoOneDayFormatted,
      dateMax: nextOneDaysFormatted,
    }));
  }, []);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div ref={ref} className="calendar_dropdown">
      <div className="calendar_dropdown-header" onClick={toggleDropdown}>
        <div onClick={() => {}} className="table_button">
          <Calendar_icon />
          <span>
            {dateState && `${dateState.dateMin} - ${dateState.dateMax}`}
          </span>
        </div>
      </div>
      <div className={`calendar_dropdown-body ${isOpen && "open"}`}>
        <span className="calendar_dropdown-body_title">Налаштувати дату</span>
        <div className="calendar_dropdown-body_calendarWrapper">
          <div
            onClick={() => {
              setOpenCalendar({
                visible: true,
                accessor: "dateMin",
                data: dateNewState.dateMin,
                minDate: null,
              });
            }}
            className="table_button"
          >
            <Calendar_icon />
            <span>{dateNewState && dateNewState.dateMin}</span>
          </div>
          <div>по</div>
          <div
            onClick={() => {
              setOpenCalendar({
                visible: true,
                accessor: "dateMax",
                data: dateNewState.dateMax,
                minDate: dateNewState.dateMin,
              });
            }}
            className="table_button"
          >
            <Calendar_icon />
            <span>{dateNewState && dateNewState.dateMax}</span>
          </div>
        </div>
        <button
          onClick={() => handleButton()}
          className="calendar_dropdown-body_button"
        >
          Застосувати
        </button>

        {isOpenCalendar.visible && (
          <Calendar
            value={parseDateString(isOpenCalendar.data)}
            onChange={onChangeCalendar}
            minDate={parseDateString(isOpenCalendar.minDate)}
          />
        )}
      </div>
    </div>
  );
};
export default DropDownCalendar;

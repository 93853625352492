import { useState } from "react";
import UseModal from "../../UseModal";

import { ReactComponent as Delete_icon } from "../../../style/img/delete_icon.svg";

const ModalDelete = ({ checkedRowLength, submit, checkedIds }) => {
  const [modalCreate, setModalCreate] = useState({
    visible: false,
    item: null,
  });

  const closeModal = () =>
    setModalCreate(() => {
      return {
        item: null,
        visible: false,
      };
    });

  const onModal = () => {
    setModalCreate(() => {
      return {
        item: null,
        visible: true,
      };
    });
  };
  const submitDelete = async () => {
    const req = await submit(checkedIds);
    if (req.payload === 200) closeModal();
  };

  return (
    <>
      <div
        onClick={() => onModal()}
        className={`table_button ${checkedRowLength > 0 ? "" : "disable"}`}
      >
        <Delete_icon />
        <span>Видалити</span>
      </div>

      <UseModal
        visible={modalCreate.visible}
        title="Підтвердження"
        content={
          <div className="modalDelete_wrapper">
            <span className="modalDelete_promo">
              Увага! Ви впевнені, що хочете видалити?
            </span>
            <div className="modalDelete_buttonWrapper">
              <button className="modalDelete_cancel" onClick={closeModal}>
                Відміна
              </button>
              <button className="modalDelete_confirm" onClick={submitDelete}>
                Видалити
              </button>
            </div>
          </div>
        }
        onClose={closeModal}
      />
    </>
  );
};

export default ModalDelete;

export const columnsTableCounterparty = [
  {
    Header: "checkbox",
    accessor: "checkbox",
  },
  {
    Header: "Код",
    accessor: "id",
  },
  {
    Header: "ПІБ",
    accessor: "name",
  },
  {
    Header: "Телефон",
    accessor: "phone",
  },
  {
    Header: "Почта",
    accessor: "email",
  },
];

import React, { useEffect, useState } from "react";
import UseTable from "../../../useHook/useTable/UseTable";
import { useDispatch, useSelector } from "react-redux";
import axios, { API_URL } from "../../../axios";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { columnsTableCounterparty } from "./components/columns";
import { getUsersByType } from "../../../store/slices/userSlice";

const User = () => {
  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.user.users);
  const totalPages = useSelector((state) => state.user.totalUsers);

  // pagination
  const [PageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    dispatch(
      getUsersByType({
        offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
      })
    );
  }, [currentPage]);

  //  state filters
  const [dataInput, setDataInput] = useState({
    // name: "",
    // phone: "",
    // login: "",
  });

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   const headers = {
  //     Authorization: `Bearer ${token}`,
  //   };
  //   const controller = new AbortController();
  //   const connectEventSource = async () => {
  //     await fetchEventSource(`${API_URL}/connect_user`, {
  //       headers,
  //       signal: controller.signal,
  //       async onopen(response) {
  //         if (response.status === 401) {
  //           const response = await axios.get(`${API_URL}/refresh`, {
  //             withCredentials: true,
  //           });
  //           localStorage.setItem("token", response.data.acsses);
  //           connectEventSource();
  //         }
  //       },
  //       onmessage() {
  //         dispatch(
  //           getUsersByType({
  //             offset: currentPage === 1 ? 0 : currentPage * PageSize - PageSize,
  //           })
  //         );
  //       },
  //     });
  //   };
  //   connectEventSource();
  //   return () => controller.abort();
  // }, [currentPage]);

  // input onChannge
  const onChangeData = (name, data) => {
    setDataInput((dataInput) => ({
      ...dataInput,
      [name]: data,
    }));
  };

  return (
    <UseTable
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      PageSize={PageSize}
      totalPages={totalPages}
      // table_nav={true}
      // tableNavLebel={"Users"}
      // data_nav={categoryData}
      data={tableData}
      columns={columnsTableCounterparty}
      onChangeData={onChangeData}
      dataInput={dataInput}
      addLink={{ to: "/users/create", label: "Додати користувача" }}
      movedButton={false}
      deleteButton={false}
    />
  );
};

export default User;

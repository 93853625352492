import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../axios";
import { changeDateFormat } from "../../utils/changeDateFormat";

export const createStockInvoiceByType = createAsyncThunk(
  "stockSlice/createStockInvoiceByType",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.post(`/stock`, formData);

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getStockInvoiceByType = createAsyncThunk(
  "stockSlice/getStockInvoiceByType",
  async (
    { type, offset = 0, limit = 25, search },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const req = await $api(
        `/stock/?type=${type}&offset=${offset}&limit=${limit}${search}`
      );

      dispatch(setData(req.data.data.data));
      dispatch(setTotal(req.data.data.total));

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteStockInvoice = createAsyncThunk(
  "stockSlice/deleteStockInvoice",
  async ({ ids, type }, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.delete("/stock", { data: { ids, type } });

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  data: [],
  total: null,
  activeCategory: null,
};

const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    // setData(state, { payload }) {
    //   state.data = payload;
    // },
    setData(state, { payload }) {
      state.data = payload.map((invoice) => {
        const user_name = invoice.User?.name || "";
        const counterparty_name = invoice?.Counterparty?.name || "";
        // const product = invoice?.StockTransactionItems.map((item) => ({
        //   quantity: item.quantity,
        //   ...item?.StockItem?.Product,
        // }));
        const time = changeDateFormat(invoice.createdAt);
        return {
          user_name,
          counterparty_name,
          // product,
          ...time,
          ...invoice,
        };
      });
    },
    setActiveCategory(state, { payload }) {
      state.activeCategory = payload;
    },
    setTotal(state, { payload }) {
      state.total = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setData, setTotal, setActiveCategory } = stockSlice.actions;

export default stockSlice.reducer;

export const columnsTableCounterparty = [
  {
    Header: "checkbox",
    accessor: "checkbox",
  },
  {
    Header: "Назва",
    accessor: "name",
  },
  {
    Header: "Значення",
    accessor: "value",
  },
];

import SignIn from "../components/SignIn/SignIn";
import MainRoutes from "../components/PrivateRoutes/MainRoutes";

import { LOGIN_ROUTE, MAIN_ROUTE } from "./constRout";

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    element: <SignIn />,
  },
];

export const privateRoutes = [
  {
    path: MAIN_ROUTE,
    element: <MainRoutes />,
  },
];

import React, { useState, useEffect, useRef } from "react";
import "./dropdown.css";

const UseDropDown = ({
  selectedItem,
  handleChange,
  data,
  disabled = false,
}) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const toggleDropdown = () => {
    if (!disabled) {
      setOpen(!isOpen);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div ref={ref} className="lang_dropdown">
      <div className="lang_dropdown-header" onClick={toggleDropdown}>
        <span className="purchaseCounterpary">{selectedItem?.name}</span>
      </div>
      <div className={`lang_dropdown-body ${isOpen && !disabled && "open"}`}>
        {data.map((item) => (
          <div
            key={item.id}
            className="lang_dropdown-item"
            onClick={() => {
              handleChange(item);
              toggleDropdown();
            }}
            id={item.id.toString()}
          >
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UseDropDown;

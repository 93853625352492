export const changeDateFormat = (created_on) => {
  const date = new Date(created_on);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const formatDate = `${day}.${month}.${year}`;
  const formatHours = `${hours}:${minutes}`;
  return { date: formatDate, hours: formatHours };
};

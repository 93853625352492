import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createSettingByTypePost } from "../../../../store/slices/settingSlice";
import button_back from "../../../../style/img/button_back.png";

const SettingCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const selectedCategory = useSelector(
    (state) => state.table.selectedCategory
  ).id;
  const titleCreate = (category) => {
    switch (category) {
      case "currency":
        return "Нова валюта";
      case "discount":
        return "Новий тип цін";
      default:
        return "";
    }
  };

  useEffect(() => {
    setTitle(titleCreate(selectedCategory));
  }, [selectedCategory]);
  return (
    <div className="catalogueCreate">
      <div className="catalogueCreate_head">
        <img
          onClick={() => navigate("/setting")}
          src={button_back}
          alt="back"
        />
        <span>{title}</span>
      </div>
      <Formik
        initialValues={{
          name: "",
          value: "",
        }}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {
          const req = await dispatch(
            createSettingByTypePost({ data: body, type: selectedCategory })
          );

          if (req.payload === 200) navigate("/setting");
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <div>
                  <span>Назва</span>
                  <Field type="text" name="name" />
                </div>
                <div>
                  <span>Значення</span>
                  <Field type="text" name="value" />
                </div>
              </div>
            </div>
          </div>

          <button className="catalogueCreate_submit" type="submit">
            Додати
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default SettingCreate;

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import loggerMiddleware from "redux-logger";
import user from "./slices/userSlice";
import table from "./slices/tableSlice";
import product from "./slices/productSlice";
import counterparty from "./slices/counterpartySlice";
import setting from "./slices/settingSlice";
import stock from "./slices/stockSlice";
import payment from "./slices/paymentSlice";

const combinedReducer = combineReducers({
  user,
  table,
  product,
  counterparty,
  setting,
  stock,
  payment,
});
const persistConfig = {
  key: "manag",
  storage,
  blacklist: ["data"],
};
const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // .concat(loggerMiddleware),
});

export const persistor = persistStore(store);
export default store;

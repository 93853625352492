export const statusData = [
  { id: "pending", name: "В ожидании" },
  { id: "сonfirmed", name: "Подтвержден" },
  { id: "shipped", name: "Отправлен" },
  { id: "delivered", name: "Доставлен" },
  { id: "cancelled", name: "Отменен" },
];

export const paymentsData = [
  { id: "pending", value: "pending", name: "В ожидании" },
  { id: "confirm", value: "confirm", name: "Подтвержден" },
];

import React from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { createUser } from "../../../../store/slices/userSlice";
import button_back from "../../../../style/img/button_back.png";

const UserCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const selectedCategory = useSelector((state) => state.table.selectedCategory);

  return (
    <div className="catalogueCreate">
      <div className="catalogueCreate_head">
        <img onClick={() => navigate("/users")} src={button_back} alt="back" />
        <span>Новий користувач</span>
      </div>
      <Formik
        initialValues={{
          name: "",
          login: "",
          password: "",
          phone: "",
        }}
        validationSchema={Yup.object({
          // password: Yup.string().required("Enter password!"),
          // login: Yup.string().required("Enter login!"),
        })}
        onSubmit={async (body) => {
          const data = body;
          data.type = "user";
          console.log(data);
          const req = await dispatch(createUser(data));

          if (req.payload === 200) navigate("/users");
        }}
      >
        <Form className="">
          <div className="catalogueCreate_input_top_wrapper">
            <div className="catalogueCreate_input_top">
              <div className="catalogueCreate_input_top_first">
                <div>
                  <span>ПІБ</span>
                  <Field type="text" name="name" />
                </div>
                <div>
                  <span>Логін</span>
                  <Field type="text" name="login" />
                </div>
                <div>
                  <span>Пароль</span>
                  <Field type="text" name="password" />
                </div>
                {/* {selectedCategory !== "worker" && (
                <>
                  <div>
                    <span>Логін</span>
                    <Field type="text" name="login" />
                  </div>
                  <div>
                    <span>Пароль</span>
                    <Field type="text" name="password" />
                  </div>
                </>
              )} */}

                <div>
                  <span>Телефон</span>
                  <Field type="text" name="phone" />
                </div>
              </div>
            </div>
          </div>

          <button className="catalogueCreate_submit" type="submit">
            Додати
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default UserCreate;

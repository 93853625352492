export const columnsTableCatalouge = [
  {
    Header: "checkbox",
    accessor: "checkbox",
  },
  {
    Header: "Артикул",
    accessor: "vendor_code",
  },
  {
    Header: "Найменування",
    accessor: "name_ua",
  },
  {
    Header: "Ціна",
    accessor: "price",
  },
];
// { id: 1, name: "test", price: 10, boking_items: 3, balance: 10, od: "шт" },

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import $api from "../../axios";

export const signIn = createAsyncThunk(
  "user/signIn",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post("/signin", formData);
      localStorage.setItem("token", response.data.access);
      dispatch(setUser(response.data));
      return response.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.post("/user", formData);

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUsersByType = createAsyncThunk(
  "user/getUsersByType",
  async ({ offset = 0, limit = 25 }, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api(`/user/?offset=${offset}&limit=${limit}`);
      dispatch(setUserDataForTable(req.data.data.users));
      dispatch(setTotalCounterparties(req.data.data.totalUsers));

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  access: null,
  lang: "ua",
  data: null,
  loading: false,
  error: null,
  users: [],
  totalUsers: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.access = action.payload.access;
      state.data = action.payload.data;
    },
    setLang(state, action) {
      state.lang = action.payload;
    },
    logout(state) {
      state.access = null;
      state.data = null;
    },
    setUserDataForTable(state, action) {
      state.users = action.payload;
    },
    setTotalCounterparties(state, action) {
      state.totalUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setUser,
  setLang,
  logout,
  setUserDataForTable,
  setTotalCounterparties,
} = userSlice.actions;

export default userSlice.reducer;

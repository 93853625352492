import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../axios";
import { setCheckboxData } from "./tableSlice";

export const createProductPost = createAsyncThunk(
  "product/createProduct",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.post("/product", formData);

      return req.status;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const putProduct = createAsyncThunk(
  "product/putProduct",
  async ({ data, id }, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.put(`/product/${id}`, data);
      dispatch(setCheckboxData([req.data.product]));
      return req.status;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteProducts = createAsyncThunk(
  "product/deleteProducts",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.delete("/product", { data: { ids: data } });

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getProductsByCatagory = createAsyncThunk(
  "product/getProductsByCatagory",
  async (
    { category, offset = 0, limit = 25, search },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const req = await $api(
        `/product/${category}?offset=${offset}&limit=${limit}${search}`
      );

      dispatch(setProducts(req.data.data.products));
      dispatch(setTotalProducts(req.data.data.totalProducts));

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

function createExtraReducers(builder, asyncThunk) {
  const { pending, fulfilled, rejected } = asyncThunk;

  builder
    .addCase(pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    })
    .addCase(rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
}

const initialState = {
  products: [],
  loading: null,
  error: null,
  totalProducts: null,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts(state, { payload }) {
      state.products = payload;
    },
    setTotalProducts(state, action) {
      state.totalProducts = action.payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducers(builder, createProductPost);
  },
});

export const { setProducts, setTotalProducts } = productSlice.actions;

export default productSlice.reducer;

import React, { useEffect, useState } from "react";
import ModalTableNavCreate from "./components/ModalTableNavCreate";
import RenderMenuItem from "./components/RenderMenuItem";
import search_icon from "../../../../style/img/button_search.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedArrayCategory,
  setSelectedCategoryStore,
} from "../../../../store/slices/tableSlice";
import {
  findCategoryById,
  findTreeRootAndIdsByTargetId,
} from "../../../../utils/findCategory";
import "./tableNav.css";

const TableNav = ({
  data_nav,
  tableNavLebel,
  createNewCategory,
  table_nav_search,
}) => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState();
  const selectedCategoryStore = useSelector(
    (state) => state.table.selectedCategory
  );

  const categoryStore = useSelector((state) => state.table.category);

  useEffect(() => {
    if (
      data_nav &&
      selectedCategoryStore &&
      findCategoryById(data_nav, selectedCategoryStore.id)
    ) {
      if (
        (tableNavLebel === "Каталог" || tableNavLebel === "Catalogue") &&
        selectedCategoryStore.parentSub_id
      ) {
        dispatch(
          setSelectedArrayCategory([selectedCategoryStore.parentSub_id])
        );
        setSelectedCategory(selectedCategoryStore);
      } else {
        dispatch(setSelectedArrayCategory([selectedCategoryStore.id]));

        if (selectedCategoryStore.subMenu) {
          setSelectedCategory(selectedCategoryStore.subMenu[0]);
        } else {
          setSelectedCategory(selectedCategoryStore);
        }
      }
    } else {
      if (tableNavLebel === "Каталог" || tableNavLebel === "Catalogue") {
        dispatch(setSelectedArrayCategory([data_nav[0].id]));
        setSelectedCategory(data_nav[0].subMenu[0]);
      } else {
        setSelectedCategory(data_nav[0]);
      }
    }
  }, [categoryStore]);

  useEffect(() => {
    if (selectedCategory) {
      dispatch(setSelectedCategoryStore(selectedCategory));
    }
  }, [selectedCategory]);
  const onSelect = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="table_sidenav">
      <div>
        <div className="table_sidenav_top">
          <span>{tableNavLebel}</span>
          {table_nav_search && <img src={search_icon} alt="search icon" />}
        </div>
        <nav className="table_navbar">
          {data_nav &&
            data_nav.map((menuItem) => (
              <RenderMenuItem
                key={menuItem.id}
                menuItem={menuItem}
                onSelect={onSelect}
                selectedCategory={selectedCategory}
                createNewCategory={createNewCategory}
              />
            ))}
        </nav>
      </div>
      <ModalTableNavCreate createNewCategory={createNewCategory} />
    </div>
  );
};

export default TableNav;

const ProductFilterInputReq = ({ data, onChangeAttribute, disable }) => {
  return (
    <div className="productFilter_form_card">
      <div className="productFilter_form_card_input-wrapper">
        <span>Назва</span>
        <input disabled={true} value={data.title_ua} type="text" />
      </div>
      <div className="productFilter_form_card_input-wrapper">
        <span>Значення ua </span>
        <input
          onChange={(e) =>
            onChangeAttribute({
              key: "value_ua",
              data: e.target.value,
              id: data.id,
            })
          }
          disabled={disable}
          value={data.value_ua}
          type="text"
        />
      </div>
      <div className="productFilter_form_card_input-wrapper">
        <span>Значення ru</span>
        <input
          onChange={(e) =>
            onChangeAttribute({
              key: "value_ru",
              data: e.target.value,
              id: data.id,
            })
          }
          disabled={disable}
          value={data.value_ru}
          type="text"
        />
      </div>
    </div>
  );
};

export default ProductFilterInputReq;

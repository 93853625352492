export function findCategoryById(categories, categoryId) {
  for (const category of categories) {
    if (category.id === categoryId) {
      return category;
    }

    if (category.subMenu && category.subMenu.length > 0) {
      const subMenuResult = findCategoryById(category.subMenu, categoryId);
      if (subMenuResult) {
        return subMenuResult;
      }
    }
  }

  return null;
}

export const findTreeRootAndIdsByTargetId = (categories, targetId) => {
  let categoryIds = [];
  const findCategory = (category, stopAfterTarget = false) => {
    const currentCategory = { ...category, subMenu: [] };
    if (category.id === targetId) {
      categoryIds.push(currentCategory.id);
      return true;
    }
    if (category.subMenu) {
      for (const subCategory of category.subMenu) {
        const found = findCategory(subCategory, stopAfterTarget);
        if (found) {
          if (!stopAfterTarget) {
            currentCategory.subMenu.push(found);
          }
          categoryIds.push(category.id);
          return true;
        }
      }
    }

    return false;
  };

  for (const category of categories) {
    if (findCategory(category, true)) {
      break;
    }
  }

  return categoryIds;
};

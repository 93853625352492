import cancel_img from "../../../../style/img/delete.png";
const ProductFilterInputAdditional = ({
  data,
  onChangeAttribute,
  disable = false,
  cancel,
}) => {
  return (
    <div className="productFilter_form_card">
      {cancel && (
        <img
          className="productFilter_form_card_cancelImg"
          src={cancel_img}
          alt="cancel attr"
          onClick={() => {
            if (!disable) {
              cancel({ id: data.id });
            }
          }}
        />
      )}
      <div className="productFilter_form_card_input-wrapper">
        <span>Назва ua</span>
        <input
          disabled={disable}
          onChange={(e) =>
            onChangeAttribute({
              key: "title_ua",
              data: e.target.value,
              id: data.id,
            })
          }
          value={data.title_ua}
          type="text"
        />
      </div>

      <div className="productFilter_form_card_input-wrapper">
        <span>Значення ua </span>
        <input
          onChange={(e) =>
            onChangeAttribute({
              key: "value_ua",
              data: e.target.value,
              id: data.id,
            })
          }
          disabled={disable}
          value={data.value_ua}
          type="text"
        />
      </div>
      <div className="productFilter_form_card_input-wrapper">
        <span>Назва ru</span>
        <input
          onChange={(e) =>
            onChangeAttribute({
              key: "title_ru",
              data: e.target.value,
              id: data.id,
            })
          }
          disabled={disable}
          value={data.title_ru}
          type="text"
        />
      </div>
      <div className="productFilter_form_card_input-wrapper">
        <span>Значення ru</span>
        <input
          onChange={(e) =>
            onChangeAttribute({
              key: "value_ru",
              data: e.target.value,
              id: data.id,
            })
          }
          disabled={disable}
          value={data.value_ru}
          type="text"
        />
      </div>
    </div>
  );
};

export default ProductFilterInputAdditional;

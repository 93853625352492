// url for request
export const makeUrl = (dataInput) => {
  let ret = "";
  if (dataInput?.dateMin) {
    if (dataInput?.dateMin.length && dataInput?.dateMax.length) {
      ret += "&date_between=" + dataInput.dateMin + "," + dataInput.dateMax;
    }
  }
  Object.entries(dataInput).map(([key, value]) => {
    if (dataInput?.dateMin || dataInput?.dateMax) return;
    ret += value.length > 0 ? "&" + key + "=" + value : "";
  });

  return ret;
};

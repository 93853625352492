import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import $api from "../../axios";

export const getCategoryTable = createAsyncThunk(
  "table/getCategoryTable",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.get("/category");

      dispatch(setCategory(req.data.data));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const createCategoryTable = createAsyncThunk(
  "table/createCategoryTable",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.post("/category", data);
      dispatch(getCategoryTable());
      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateCategoryTable = createAsyncThunk(
  "table/updateCategoryTable",
  async ({ data, id }, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.put(`/category/${id}`, data);
      dispatch(getCategoryTable());
      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addAttrCategoryTable = createAsyncThunk(
  "table/addAttrCategoryTable",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.post(`/category/${id}`, data);
      dispatch(getCategoryTable());
      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteCategoryAttribute = createAsyncThunk(
  "table/deleteCategoryAttribute",
  async ({ id, attr_id }, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.delete(`/category/${id}`, { data: { attr_id } });
      dispatch(getCategoryTable());
      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCatagory = createAsyncThunk(
  "table/deleteCatagory",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.delete(`/category`, { data: { ids: data } });

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getInvoiceById = createAsyncThunk(
  "table/deleteCategoryAttribute",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.get(`/invoices/${id}`, id);

      return req.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateInvoiceById = createAsyncThunk(
  "table/updateInvoiceById",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api.put(`/invoices/${data.id}`, data);

      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getProductByIdWithFile = createAsyncThunk(
  "table/getProductByIdWithFile",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const req = await $api(`/product_with_file/${id}`);
      dispatch(checkboxForDetailsOrDelete([req.data.data]));
      return req.status;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const setPending = (state) => {
  state.loading = true;
};
const setRejected = (state) => {
  state.loading = false;
};

const table = createSlice({
  name: "data",
  initialState: {
    data: null,
    category: [],
    selectedCategoryGroup: [],
    selectedCategory: null,
    checkboxData: [],
    checkboxForDetailsOrDelete: [],
    selectedStatus: null,
    date: { dateMin: "", dateMax: "" },
    error: null,
    loading: false,
    excel: null,
  },
  reducers: {
    setCategory(state, { payload }) {
      state.category = [
        ...payload,
        {
          id: "without",
          name: "Без категорії",
          parentSub_id: null,
        },
      ];
    },
    setCheckboxData(state, { payload }) {
      state.checkboxData = payload;
    },
    setDate(state, { payload }) {
      state.date = payload;
    },
    checkboxForDetailsOrDelete(state, { payload }) {
      state.checkboxForDetailsOrDelete = payload;
    },

    handleCounterCheckboxData(state, { payload }) {
      state.checkboxData = state.checkboxData.map((item) => {
        if (item.id === payload.id) {
          if (payload.accessor === "increment") {
            return {
              ...item,
              quantity_application:
                (parseInt(item.quantity_application) || 0) + 1,
              price_sum:
                ((parseInt(item.quantity_application) || 0) + 1) * item.price,
            };
          } else if (payload.accessor === "decrement") {
            return {
              ...item,
              quantity_application: Math.max(1, item.quantity_application - 1),
              price_sum:
                Math.max(1, item.quantity_application - 1) * item.price,
            };
          }
        }
        return item;
      });
    },
    onChangeCheckboxData(state, { payload }) {
      state.checkboxData = state.checkboxData.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            quantity_application: payload.value,
            sum: payload.value * item.price,
          };
        }
        return item;
      });
    },
    toggleCheckedChanged: (state, action) => {
      const item = action.payload;
      const findItem = state.checkboxData.find(
        (checked) => checked.id === item.id
      );

      if (findItem) {
        state.checkboxData = state.checkboxData.filter(
          (checked) => checked.id !== item.id
        );
      } else {
        const newItem = { ...item, quantity_application: 1 };
        state.checkboxData = [...state.checkboxData, newItem];
      }
    },
    cancelItemCheckboxData(state, { payload }) {
      state.checkboxData = state.checkboxData.filter(
        (item) => item.id !== payload
      );
    },

    setSelectedArrayCategory(state, { payload }) {
      state.selectedCategoryGroup = payload;
    },
    setSelectedCategoryStore(state, { payload }) {
      state.selectedCategory = payload;
    },
    setCheckedStatus(state, { payload }) {
      state.selectedStatus = payload;
    },
  },
});

export const {
  setCategory,
  setSelectedArrayCategory,
  setSelectedCategoryStore,
  setCheckboxData,
  addCheckboxData,
  handleCounterCheckboxData,
  cancelItemCheckboxData,
  toggleCheckedChanged,
  checkboxForDetailsOrDelete,
  onChangeCheckboxData,
  setDate,
  makeDataForInvoice,
  setCheckedStatus,
} = table.actions;

export default table.reducer;

import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./slider.css";
import no_img from "../../style/img/no_image.png";

const items = [
  <img
    src={no_img}
    onDragStart={(e) => e.preventDefault()}
    role="presentation"
    className="carousel-image"
  />,
];

const renderPrevButton = ({ isDisabled }) => (
  <button
    type="button"
    className="carousel-button carousel-button-prev"
    disabled={isDisabled}
  >
    {"<"}
  </button>
);

const renderNextButton = ({ isDisabled }) => (
  <button
    type="button"
    className="carousel-button carousel-button-next"
    disabled={isDisabled}
  >
    {">"}
  </button>
);

const renderDotsItem = ({ isActive }) => (
  <div className={`carousel-dot ${isActive ? "active" : ""}`} />
);

const UseSlider = ({ images }) => {
  return (
    <div className="carousel-container">
      <AliceCarousel
        mouseTracking
        items={images.length > 0 ? images : items}
        renderPrevButton={renderPrevButton}
        renderNextButton={renderNextButton}
        renderDotsItem={renderDotsItem}
        responsive={{
          0: { items: 1 },
          600: { items: 1 },
          1024: { items: 1 },
        }}
      />
    </div>
  );
};

export default UseSlider;

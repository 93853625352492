import React from "react";
import { saveExcel } from "./saveExcel";
const Exel = ({ data, title }) => {
  return (
    <button
      style={{
        padding: "10px 20px",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        background: "#008947",
      }}
      onClick={() => saveExcel({ data, title })}
    >
      Exel
    </button>
  );
};

export default Exel;

import React from "react";
import { ReactComponent as Delete_icon } from "../../../../../../style/img/delete_icon.svg";
import { deleteCategoryAttribute } from "../../../../../../store/slices/tableSlice";
import { useDispatch } from "react-redux";
import ModalDelAttribute from "./ModalDelAttribute";
const SubAttributes = ({ data, category_id }) => {
  const dispatch = useDispatch();
  const deleteFunc = (attr_id) =>
    dispatch(deleteCategoryAttribute({ attr_id, id: category_id }));
  return (
    <div
      style={{
        marginTop: "10px",
        borderTop: "1px solid #dbdbdb",
        borderBottom: "1px solid #dbdbdb",
        padding: "10px 0",
        maxHeight: "200px",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        gap: "10px 0",
      }}
    >
      {data &&
        data.map((item) => (
          <div
            key={item.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 20px",
              border: "1px solid #008947",
              borderRadius: "9px",
              color: "#008947",
              backgroundColor: "#E8FCEF",
            }}
          >
            <span key={item.id}>{item.title_ua}</span>

            <ModalDelAttribute submit={deleteFunc} attr_id={item.id} />
          </div>
        ))}
    </div>
  );
};

export default SubAttributes;

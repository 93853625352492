export const columnApplication = [
  {
    Header: "delete",
    accessor: "delete",
  },

  {
    Header: "Вибраний товар",
    accessor: "name_ua",
  },
  {
    Header: "Додати",
    accessor: "toggle_q",
  },
  {
    Header: "Кількість",
    accessor: "quantity_application",
  },
  {
    Header: "Сума",
    accessor: "price_sum",
  },
];

export const columnsTablePurchase = [
  {
    Header: "checkbox",
    accessor: "checkbox",
  },
  {
    Header: "Код",
    accessor: "id",
  },
  {
    Header: "Статус",
    accessor: "status",
  },

  {
    Header: "Покупець",
    accessor: "counterparty_name",
  },
  {
    Header: "Ціна UAH",
    accessor: "price_sum_UAH",
  },
  {
    Header: "Ціна USD",
    accessor: "price_sum_USD",
  },
  {
    Header: "Дата",
    accessor: "date",
  },
  {
    Header: "Час",
    accessor: "hours",
  },
];
export const columnCreatePurchase = [
  {
    Header: "delete",
    accessor: "delete",
  },

  {
    Header: "Вибраний товар",
    accessor: "name",
  },
  {
    Header: "Додати",
    accessor: "toggle_q",
  },
  {
    Header: "Кількість",
    accessor: "quantity_application",
  },
];
